<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    :siderWidth="239"
    v-bind="settings"
  >
    <template v-slot:menuRender>
    <a-menu
      :open-keys.sync="openKeys"
      :selectedKeys="selectedKeys"
      mode="inline"
    >
      <template v-for="item in handlerMenus">
        <a-sub-menu :key="item.path" v-if="item.children && item.children.length>0">
        <span slot="title"><i :class="`anticon-${ item?.meta?.icon }`" class="anticon"></i><span :class="{'has-point':item.hasPoint}">{{ item?.meta?.title }}</span></span>
          <a-menu-item v-for="child in item.children" :key="child.path">
            <a :href="`#${child.path}`"><span :class="{'has-point':child.hasPoint}">{{ child?.meta?.title }}</span></a></a-menu-item>
        </a-sub-menu>
        <a-menu-item v-else :key="item.path">
          <a :href="`#${ item.path }`"><i :class="`anticon-${ item?.meta?.icon }`" class="anticon"></i><span>{{ item?.meta?.title }}</span></a>
        </a-menu-item>
      </template>
    </a-menu>
    </template>
    <!-- Ads begin
      广告代码 真实项目中请移除
      production remove this Ads
    -->
    <!-- <ads v-if="isProPreviewSite && !collapsed"/> -->
    <!-- Ads end -->

    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div style="text-align: center; font-weight: bold; font-size: 30px;">
        <!-- <img src="@/assets/logo.svg" /> -->
        <div style="margin: 0; border-bottom: 2px solid #F5F5F5; height: 106px; font-size: 30px; color: #15C5CE !important;;">{{ title }}</div>
      </div>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
   <template v-slot:headerContentRender>
      <div>
        <!-- <a-tooltip title="刷新页面">
          <a-icon type="reload" style="font-size: 18px;cursor: pointer;" @click="() => { $message.info('只是一个DEMO') }" />
        </a-tooltip> -->
		<h2 style="font-size: 20px; font-weight: 500; color: #252929;font-family: PingFang SC-Medium, PingFang SC;">知识管理系统</h2>
      </div>
    </template>

<!--    <setting-drawer v-if="isDev" :settings="settings" @change="handleSettingChange">
      <div style="margin: 12px 0;">
        This is SettingDrawer custom footer content.
      </div>
    </setting-drawer> -->
   <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'"/>
    </template>

    <!-- custom footer / 自定义Footer -->
<!--    <template v-slot:footerRender>
      <global-footer />
    </template> -->
    <router-view />
  </pro-layout>
</template>

<script>
import lodash from "lodash"
import {
  showRedDot,
} from "@/api/permission";
import storage from 'store'
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'

export default {
  name: 'BasicLayout',
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter,
    Ads
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      isDev: process.env.NODE_ENV === 'development' || process.env.VUE_APP_PREVIEW === 'true',
      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: true,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      openKeys: [],
      handlerMenus:[], // 处理后的菜单
      selectedKeys:[], // 选中的key
      redDotData:{
        "exam_correct_red_dot": {
            "high_school_red_dot": true,
            "middle_school_red_dot": true,
            "primary_school_red_dot": true
        },
        "exam_quality_dot": {
            "high_school_red_dot": true,
            "middle_school_red_dot": true,
            "primary_school_red_dot": true
        },
        "error_queue_red_dot": {
            "high_school_red_dot": true,
            "middle_school_red_dot": true,
            "primary_school_red_dot": true
        }
      }, // 红点数据
    }
  },
  computed: {
    // 批改质检父菜单
    correctionMenuObj(){
      return this.handlerMenus.find(item=>item.path === "/correction_and_testing") || {};
    },
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters,
    }),
    // 批改质检父菜单
    correctionMenuObj(){
      return this.handlerMenus.find(item=>item.path === "/correction_and_testing");
    },
  },
  watch:{
    "$route.path":{
      handler(val){
        if(this.correctionMenuObj){
          console.log("拉取接口");
          this.getRedDot();
        }
        // 根据path判断openkey
        this.selectedKeys = [val];
        let key = this.getParentKey(val)
        this.openKeys = [key];
      },
      deep:true,
    }
  },
  created () {
    const routes = this.mainMenu.find(item => item.path === '/')
    this.menus = (routes && routes.children) || []
    this.menus.forEach((item, index) => {
      if (item?.meta?.hideInMenu) {
        this.menus.splice(index, 1)
      }
    })
    this.handlerMenus = lodash.cloneDeep(this.menus);
    console.log(this.handlerMenus,"this.handlerMenus");
    this.selectedKeys = [this.$route.path];
    let key = this.getParentKey(this.$route.path)
    this.openKeys = [key];
    // 如果有批改这个菜单才处理红点
    if(this.correctionMenuObj){
      this.getRedDot();
    }
    // 处理侧栏收起状态
    window.addEventListener('resize', this.handleResize)
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
    this.$bus.$on("changePhaseTab", this.changePhaseTab);
    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    // if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
    //   updateTheme(this.settings.primaryColor)
    // }
  },
  beforeDestroy () {
    this.$bus.$off("changePhaseTab");
  },
  methods: {
    async getRedDot(){
      const user_name = storage.get("user_name")
      let params = {
        phone:user_name
      };
      try {
        let res = await showRedDot(params)
        if(res.code == 200){
          this.redDotData = res?.data || {
            "exam_correct_red_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            },
            "exam_quality_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            },
            "error_queue_red_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            }
          };
          this.handlePoint();
        }else{
          this.redDotData = {
            "exam_correct_red_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            },
            "exam_quality_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            },
            "error_queue_red_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            }
          };
          this.$message.error(`获取红点提示失败,原因是${res.message}`)
        } 
      } catch (error) {
        this.redDotData = {
            "exam_correct_red_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            },
            "exam_quality_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            },
            "error_queue_red_dot": {
                "high_school_red_dot": false,
                "middle_school_red_dot": false,
                "primary_school_red_dot": false
            }
          };
          console.log(error);
          this.$message.error(`获取红点提示异常,原因是${error}`)
      }
    },
    getParentKey(val){
      let obj = this.handlerMenus.find(item=>item.path === val || ( item.children && item.children.length >0 && item.children.some(children=>children.path === val) ));
      return obj.path;
    },
    changePhaseTab(data){
      console.log(data,'redData');
      this.redDotData = data;
      this.handlePoint()
    },
    handlePoint(){
      const arr = [];
      for (const key in this.redDotData) {
        if (this.redDotData.hasOwnProperty(key)) {
          const subObj = this.redDotData[key];
          let obj = this.correctionMenuObj.children.find(child=>child.meta.redDotKey === key);
          if(!obj){
            continue;
          }
          if (subObj.high_school_red_dot || subObj.middle_school_red_dot || subObj.primary_school_red_dot) {
            arr.push(key);
            this.$set(obj,'hasPoint',true);
          }else{
            this.$set(obj,'hasPoint',false);
          }
        }
      }
      this.correctionMenuObj.hasPoint = arr.length>0;
    },
    i18nRender,
    handleResize() {
      this.windowWidth = window.innerWidth;
      if(this.windowWidth < 1000){
        this.collapsed = true
      }else{
        this.collapsed = false
      }
    },
    handleMediaQuery (val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse (val) {
      this.collapsed = val
    },
    handleSettingChange ({ type, value }) {
      console.log('type', type, value)
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-menu-item:hover{
  color: #545757;
}
.ant-menu-item-selected{
  color: #15C5CE !important;
}
.has-point{
  position: relative;
  &::before{
    content: '';
    width: 6px;
    height: 6px;
    background: #15C5CE;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top:0;
  }
}
</style>
<style lang="less">
@import "./BasicLayout.less";
</style>
