import request from '@/utils/request'
const permission = {
  roleList:'/auth/v1/get_existed_role_list', //获取已有角色列表
  dispatchRole:'/auth/v1/dispatch_role', //新增角色
  filterRole:'/auth/v1/filter_role', //筛选角色状态
  configRole:'/auth/v1/config_role', //获取角色管理配置
  deleteRole:'/auth/v1/delete_role', //删除角色
  disableRole:'/auth/v1/disable_role', //启用禁用角色
  bindRole:'/auth/v1/is_bind_role', //删除该角色是否与账号关联
  existRole:'/auth/v1/is_role_name_exist', //禁用该角色是否与账号关联
  editRoleName:'/auth/v1/edit_role_name', //编辑角色名称
  roleAuthConfig:'/auth/v1/edit_role_auth_config', //编辑角色权限配置
  

  accountList :'/auth/v1/filter_account', //获取账号列表
  addAccount :'/auth/v1/add_account', //账户管理->添加账号
  searchAccount :'/auth/v1/search_account', //账户管理->搜索框
  deleteAccount :'/auth/v1/delete_account', //账户管理->删除
  disableAccount :'/auth/v1/disable_account', //账户管理->禁用启用
  editAccount :'/auth/v1/edit_account', //账户管理->修改账号信息
  existPhone: '/auth/v1/check_account_is_exist', //验证手机号是否存在
  accountSms: '/auth/v1/send_create_account_sms', //发送账户申请
  showRedDot: '/auth/v1/show_red_dot', // 展示红点
}

//获取已有角色列表
export function roleList(parameter) {
  return request({
    url: permission.roleList,
    method: 'get',
    params: parameter
  })
}

//获取角色管理配置
export function configRole(parameter) {
  return request({
    url: permission.configRole,
    method: 'get',
    params: parameter
  })
}


//删除角色筛选角色状态
export function accountList(parameter) {
  return request({
    url: permission.accountList,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//删除角色
export function deleteRole(parameter) {
  return request({
    url: permission.deleteRole,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//启用禁用角色
export function disableRole(parameter) {
  return request({
    url: permission.disableRole,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//该角色是否与角色关联
export function bindRole(parameter) {
  return request({
    url: permission.bindRole,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//禁用该角色是否与账号关联
export function existRole(parameter) {
  return request({
    url: permission.existRole,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//编辑角色名称
export function editRoleName(parameter) {
  return request({
    url: permission.editRoleName,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//编辑角色权限
export function roleAuthConfig(parameter) {
  return request({
    url: permission.roleAuthConfig,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->添加账号
export function addAccount(parameter) {
  return request({
    url: permission.addAccount,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->搜索框
export function searchAccount(parameter) {
  return request({
    url: permission.searchAccount,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->删除
export function deleteAccount(parameter) {
  return request({
    url: permission.deleteAccount,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->禁用启用
export function disableAccount(parameter) {
  return request({
    url: permission.disableAccount,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->修改账号信息
export function editAccount(parameter) {
  return request({
    url: permission.editAccount,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->验证手机号是否存在
export function existPhone(parameter) {
  return request({
    url: permission.existPhone,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//账户管理->发送账户申请
export function accountSms(parameter) {
  return request({
    url: permission.accountSms,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//新增角色
export function dispatchRole(parameter) {
  return request({
    url: permission.dispatchRole,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//筛选角色状态
export function filterRole(parameter) {
  return request({
    url: permission.filterRole,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//获取请求任务下的全部题目列表
export function taskAbnormal(id) {
  const url = `${titlesRequest.taskAbnormal}/${id}`
  return request({
    url,
    method: 'get',
  })
}

//删除请求任务
export function delRequest(id) {
  const url = `${titlesRequest.delRequest}/${id}`
  return request({
    url,
    method: 'delete'
  })
}

//标记为异常题目
export function markException(id, parameter) {
  const url = `${titlesRequest.markException}/${id}`
  return request({
    url,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//重启请求任务
export function restartRequest(id) {
  const url = `${titlesRequest.restartRequest}/${id}`
  return request({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


//添加至题目标注
export function transferMark(id) {
  const url = `${titlesRequest.transferMark}/${id}`
  return request({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

//停用请求任务
export function stopTask(id) {
  const url = `${titlesRequest.stopTask}/${id}`
  return request({
    url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 展示红点
export function showRedDot(parameter) {
  return request({
    url: permission.showRedDot,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}